<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{$t('i18nn_87f891127ffff1f8')}}</span>
									<!-- <el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
									  <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
									</el-tooltip> -->
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<!-- <el-button  @click="updatePartnerDataAction($event,$t('4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">{{$t('i18nn_4f62f8d07bb4054f')}}</el-button> -->
								<!-- <span v-if="!isSel"> -->
								<!-- <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null, $t('i18nn_24c8f46012a25c89'))">{{$t('c0246c55b9cac963')}}</el-button> -->

								<!-- <el-button @click="completeAction($event,null)" type="danger" size="small" icon="el-icon-finished">{{$t('i18nn_de57dcdfc3e01860')}}</el-button> -->
								<!-- </span> -->
								<!--右边容器-->
								<!-- <slot name="topRight"></slot> -->
								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<div>
						<div class="filterCon" style="">
							<!-- <el-row type="flex" justify="end"> -->
							<!-- <el-col :span="24"> -->
							<ul class="filterConList">
								<!-- <li>
                      <span>{{$t('i18nn_cabc5dc617eef30e')}}</span>

                      <el-date-picker
                        v-model="filterData.statisticsCurrentDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        size="small"
                        :placeholder="$t('68efabd029c860a9')"
                        @change="initData()"
                      ></el-date-picker>
                    </li> -->
								<!-- <li>
									<span>{{ $t('hytxs0000060') }}</span>
									<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
								</li> -->
								<li>
									<span>{{$t('i18nn_d42355e6aef72227')}}</span>
									<!-- <el-date-picker v-model="dataStr" size="small" type="daterange"
										align="right" :clearable="true" unlink-panels range-separator="-"
										start-placeholder="start date" end-placeholder="end date"
										:picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										@change="initData()"></el-date-picker> -->
									<el-date-picker v-model="dateStr" size="small" align="right" type="date"
										placeholder="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										:picker-options="pickerOptions" @change="initData()">
									</el-date-picker>
								</li>
								<!-- <li class="">
											<span>SKU</span>
											<el-input
												:placeholder="$t('FormMsg.Please_Enter')"
												size="small"
												v-model="filterData.goodsSku"
												maxlength="50"
												:clearable="true"
												style="width: 250px;"
											></el-input>
										</li> -->

								<!-- <li class="">
									<span>{{ $t('c944a6686d996ab3') }}</span>
									<whNoSelect @changeData="changWhNo"></whNoSelect>
								</li> -->

								<li>
									<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
										{{ $t('1e7246dd6ccc5539') }}
									</el-button>
								</li>
							</ul>
							<!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee"  :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>                </el-select> -->
							<!-- </el-col> -->
							<!-- </el-row> -->
						</div>
					</div>

					<div>
						<el-card style="margin-bottom: 10px;">
							<!-- <div slot="header"><span>{{$t('684a2afb069b6016')}}</span></div> -->
							<div>
								<CargoVolumeByCharts :openTime="ChartsMonDataOpenTime" :parm="ChartsMonDataParm"
									:chartData="ChartDataMon" @clickMon="clickMon"></CargoVolumeByCharts>
							</div>
						</el-card>
						<el-card style="margin-bottom: 10px;">
							<div slot="header">
								<!-- <li class=""> -->
									<span>{{ $t('c944a6686d996ab3') }}</span>
									<whNoSelect ref="whNoSelect" @changeData="changWhNo" :clearable="false"></whNoSelect>
								<!-- </li> -->
							</div>
							<div>
								
								<el-tabs type="" v-model="activeName">
									<el-tab-pane :label="$t('i18nn_588fde1c3de565ab')" name="first">
									<CargoVolumeByCharts :openTime="ChartsDayDataOpenTime" :parm="ChartsDayDataParm"
										:chartData="ChartDataDay"></CargoVolumeByCharts>
									</el-tab-pane>
									<el-tab-pane :label="$t('i18nn_16aa273f2941c9b5')" name="second">
										<el-table ref="multipleTable" :data="detWhData" stripe :border="true"
											:height="$store.state.tableMaxHeight8"
											style="width: 100%;margin-bottom: 10px;" size="small">
											<el-table-column type="index" fixed="left" width="50" align="center"
												:label="$t('7b1c2b1adc920d9c')"></el-table-column>
												
											<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip></el-table-column>
											
											<el-table-column prop="totalVol" :label="$t('hytxs0000010')"></el-table-column>
											<el-table-column prop="totalWeight" :label="$t('hytxs0000009')">
											</el-table-column>
											<el-table-column prop="stock" :label="$t('i18nn_c1b676e21af630d2')">
											</el-table-column>
											<el-table-column :label="$t('93f5ca01b006206c')" width="180px">
												<template slot-scope="scope">
													<div>
														<el-button size="small" type="primary"
															@click="toWhDet($event,scope.row)"
															icon="el-icon-right"><span>{{$t('i18nn_377fa66b0b28ce31')}}</span>SKU<span>{{$t('i18nn_de53ab10322cd38a')}}</span></el-button>
													</div>
												</template>
											</el-table-column>
										</el-table>
									</el-tab-pane>
								</el-tabs>
									
								
							</div>
						</el-card>
					</div>
				</div>
				<!-- <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div> -->
			</div>
		</div>
		<!-- excel 导出排序 -->
		<!-- <whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhReportSkuGroupStockPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom> -->
		<!--sku趋势统计-->
		<!-- <DialogInStSkuCharts :openTime="ChartsDataOpenTime" :parm="ChartsDataParm"></DialogInStSkuCharts> -->
	</div>
</template>
<script>
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	// import whExcelCustdom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';

	// import DialogInStSkuCharts from '@/components/WarehouseCenter2/statement/DialogInStSkuCharts.vue';

	import CargoVolumeByCharts from '@/components/WarehouseCenter2/statement/CargoVolumeByCharts.vue';

	export default {
		components: {
			// WhPlace,
			cusSelFuzzy,
			whNoSelect,
			// whExcelCustom,
			// DialogInStSkuCharts,
			CargoVolumeByCharts
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				// popTest : false,
				pickerOptions: this.$PickerDate.pickerOptions_5(),
				ChartsMonDataOpenTime: '',
				ChartsMonDataParm: {},
				ChartDataMon: {},

				ChartsDayDataOpenTime: '',
				ChartsDayDataParm: {},
				ChartDataDay: {},

				AllData: [],
				detWhData: [],
				
				activeName:'first',
				// ChartsData2OpenTime: '',
				// ChartsData2Parm: {},
				// ChartData2:{},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				// tableData: {},
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 10, 20, 30],
				// 	page_size: 20,
				// 	total: 0
				// },

				selectOption: {
					wh_no: []
					// wh_goods_fee_type: [],
					// wh_vol_unit:[],
					// statusList: [
					// 	{
					// 		value: '',
					// 		label: this.$t('16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '10',
					// 		label:this.$t('7e14d2cd3996dcd2')
					// 	},
					// 	{
					// 		value: '45',
					// 		label:this.$t('i18nn_885c3cdb72cb2e0b')
					// 	}
					// ]
				},
				dateStr: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()),
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序

					// agentUser: '',
					// // "accountPeriod":"",
					// daterange: [],
					// goodsSku: '',
					whNo: '',
					// userId: ''
					// goodsSku: '',
					// goodsName:""
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoData();
		},
		methods: {
			initData() {
				// this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getPageData2();
				this.$nextTick(() => {
					// this.$refs.multipleTable.doLayout();
				});
				// this.getDicData();
			},

			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.clickMon(this.filterData.whNo)
			},
			//导出 excel
			// exportExcel() {
			// 	let columns = [
			// 		{
			// 			title: 'SKU',
			// 			key: 'goodsSku'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_cabc5dc617eef30e'),
			// 			key: 'reportDate'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_de53ab10322cd38a'),
			// 			key: 'stock'
			// 		},
			// 		{
			// 			title: '总体积(m³)',
			// 			key: 'totalVol'
			// 		},
			// 		{
			// 			title: '总重量(kg)',
			// 			key: 'totalWeight'
			// 		},
			// 		{
			//  		title: this.$t('5acbec83efb27445'),
			// 			key: 'whNo'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_0e240c5802d17fea'),
			// 			key: 'vol'
			// 		},
			// 		{
			// 			title: this.$t('i18nn_11e41b2ec3d872ed'),
			// 			key: 'weight'
			// 		}
			// 	];
			// 	let Data = this.tableData;

			// 	this.expExcelData = Data;
			// 	this.excelHead = columns;
			// 	this.excelName = 'InventoryStatisticsSkuGroup';
			// 	this.excelOption = { height: 20 };
			// 	this.excelOpenTime = new Date().getTime();
			// },

			//分页的筛选项数据
			pageFilterData() {
				// console.log('daterange', this.filterData.daterange);
				// let startTime = '';
				// let endTime = '';
				// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				// 	startTime = this.filterData.daterange[0];
				// 	endTime = this.filterData.daterange[1];
				// } else {
				// 	// this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
				// }
				// startD endD
				
				return {
					"startTime": this.dateStr
				};
			},

			//请求分页数据
			getPageData() {
				// let _this = this;

				// let filterData = Object.assign({
				// 	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
				// 	limit: this.pagination.page_size, //当前页显示数目
				// },this.pageFilterData())

				let filterData = this.pageFilterData();
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhSkuDayStockCurrentVolume, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code && data.rows) {
							//表格显示数据
							// this.tableData = data.data;
							let charData = data.rows;
							// charData.SDS = charData.SDS?charData.SDS:[];
							// charData.STF = charData.STF?charData.STF:[];
							this.AllData = charData;
							this.initChar(charData);
							if (charData.length > 0) {
								this.initChar2(charData[0]);
							}

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//请求分页数据
			// getPageData2(dateMon) {
			// 	// let _this = this;

			// 	// let filterData = Object.assign({
			// 	// 	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 	// 	limit: this.pagination.page_size, //当前页显示数目
			// 	// },this.pageFilterData())

			// 	let filterData = this.pageFilterData();
			// 	//显示选择数据最后月的当月数据(1号至当月最后一号)
			// 	let titleMon = "";
			// 	//点击的值
			// 	if(dateMon){
			// 		filterData.endD = dateMon;
			// 	}  
			// 	//筛选传入的，最后月的值
			// 	if(filterData.endD){
			// 		try{
			// 			let newDataSec = this.$Utils.getMonByDaySection(filterData.endD);
			// 			console.log("newDataSec",newDataSec);
			// 			filterData.startD = newDataSec[0] ? newDataSec[0] : null;
			// 			filterData.endD = newDataSec[1] ? newDataSec[1] : null;
			// 			titleMon = "("+newDataSec[2]+")";
			// 		}catch(e){
			// 			console.log(e)
			// 			//TODO handle the exception
			// 		}
			// 	}

			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhOrderStatisticsByDay, filterData)
			// 		.then(({ data }) => {
			// 			
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.data) {
			// 				//表格显示数据
			// 				// this.tableData = data.data;
			// 				let charData = data.data;
			// 				charData.SDS = charData.SDS?charData.SDS:[];
			// 				charData.STF = charData.STF?charData.STF:[];

			// 				this.initChar2(charData.SDS,charData.STF,titleMon);

			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error(this.$t('hytxs0000030'));
			// 			this.loading_load = false;
			// 		});
			// },
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},

			//打开SKU趋势统计详情
			// openSkuCharts(event, row) {
			// 	event.stopPropagation();
			// 	this.ChartsDataParm = {
			// 		userId: row.userId, //"用户ID",
			// 		goodsSku: row.goodsSku, //this.$t('i18nn_74ed28f373ea14da'),
			// 		whNo: row.whNo //this.$t('5acbec83efb27445')
			// 	};
			// 	this.ChartsDataOpenTime = new Date().getTime();
			// },

			initChar(data) {
				this.ChartsMonDataParm = {
					xAxisName: this.$t('c944a6686d996ab3'),
					// seriesName: [this.$t('i18nn_f340f0ba85ea230d'), this.$t('96f1a4b17e75892d')],
					seriesName: [this.$t('i18nn_f340f0ba85ea230d')]
				};

				let XData = [],
					YData1 = [],
					YData2 = [];
				data.forEach(item => {
					XData.push(item.whNo);
					YData1.push(item.totalVol);
					// YData2.push(item.totalWeight);
				});
				// data2.forEach(item=>{
				// 	YData2.push(item.totalCount);
				// });
				this.ChartDataMon = {
					title: this.$t('i18nn_051e757f5c7e8026')+'('+this.$t('16853bda54120bf1')+')('+this.$t('i18nn_f340f0ba85ea230d')+'m³)',
					type: '1',
					XData: XData,
					YData1: YData1,
					// YData2: YData2
				}
				this.ChartsMonDataOpenTime = new Date().getTime();
			},

			initChar2(data) {
				this.filterData.whNo = data.whNo;
				this.$nextTick(()=>{
					this.$refs.whNoSelect.init(this.filterData.whNo);
				})
				
				this.ChartsDayDataParm = {
					xAxisName: this.$t('i18nn_18d7892498938435'),
					// seriesName: [this.$t('i18nn_f340f0ba85ea230d'), this.$t('96f1a4b17e75892d')],
					seriesName: [this.$t('i18nn_f340f0ba85ea230d')]
				};

				let XData = [],
					YData1 = [],
					YData2 = [];
				this.detWhData = [];
					if(data.records && data.records.length>0){
						data.records.forEach(item => {
							XData.push(item.cusName);
							YData1.push(item.totalVol);
							// YData2.push(item.totalWeight);
						});
						this.detWhData = data.records;
					}
				

				this.ChartDataDay = {
					title: this.$t('i18nn_051e757f5c7e8026') + data.whNo,
					XData: XData,
					YData1: YData1,
					// YData2: YData2
				}
				this.ChartsDayDataOpenTime = new Date().getTime();
			},

			//点击
			clickMon(val) {
				// this.getPageData2(val);
				
				let selObj = this.AllData.find(item => {
					return val == item.whNo;
				});
				if(selObj){
					this.initChar2(selObj);
				} else {
					this.$message.warning(this.$t('i18nn_5f0cbc011b37740b'));
				}

			},

			//
			toWhDet(event, row) {
				event.stopPropagation();
				this.$router.push({name:"InventoryStatisticsSkuGroup",query:{whNo:row.whNo,userId:row.userId,date:this.dateStr}})
				// this.activeName_cus = "second";
				// this.filterData.userId = row.userId;
				// this.selCusName = row.cusName;
				// // this.$refs.cusSelFuzzy.init(row.userId);
				// this.serAllFeeCusAndWhData();
				// let userId = this.filterData.userId;
				// this.getAllFeeCusAndWhData(userId);
			},
			// initCharSTF(data){
			// 	this.ChartsData2Parm = { };
			// 	this.ChartsData2OpenTime = new Date().getTime();
			// 	let XData = [],YData=[];
			// 	data.forEach(item=>{
			// 		XData.push(item.commitDate);
			// 		YData.push(item.totalCount);
			// 	});
			// 	this.ChartData2 = {
			// 		title: "自提单-订单按月统计",
			// 		XData: XData,
			// 		YData: YData
			// 	}
			// },

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//查询仓库
			// getWhNoData() {
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList)
			// 		.then(({ data }) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询仓库失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_323ee425eca7208c'));
			// 			this.$message.error('查询仓库失败,请重试！');
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_goods_fee_type'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-table__body {
	// 	.el-input {
	// 		input {
	// 			padding-left: 5px;
	// 			padding-right: 5px;
	// 		}
	// 	}
	// 	.el-input-number,
	// 	.el-select {
	// 		width: 100px;
	// 		.el-input__inner {
	// 			text-align: left;
	// 		}
	// 	}
	// }
	// .tb-in-row {
	// 	display: flex;
	// 	.tb-in-name {
	// 		width: 100px;
	// 		text-align: right;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
	// .simpleTable {
	//   width: 100%;
	//   border: 1px solid #e5e5e5;
	//   tr {
	//     td {
	//       border: 1px solid #e5e5e5;
	//     }
	//   }
	// }
</style>
